/* eslint-disable */
import axios from 'axios';
import { getToken } from '../utils/Storage';

let baseUrl = process.env.REACT_APP_API_URL;
class ApiService {
  authGet = (route, params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .get(baseUrl + route, { params })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          // if (response && response.status === 400) {
          //   resolve(response);
          // }
          // if (response && response.status === 401) {
          //   window.location.assign('/auth/login');
          //   resolve(response);
          // }
          resolve(response);
        });
    });
  };
  get = (route, params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .get(baseUrl + route, {
          params,
          headers: {
            ['Authorization']: getToken(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response && response.status === 400) {
            resolve(response);
          }
          if (response && response.status === 401) {
            localStorage.clear();
            window.location.assign('/auth/401');
            resolve(response);
          }
          reject(response);
        });
    });
  };
  authPost = async(route, params) => {
    return await new Promise((resolve, reject) => {
      axios
        .post(baseUrl + route, params)
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response && response.status === 400) {
            resolve(response);
          }
          if (response && response.status === 401) {
            // localStorage.clear();
            // window.location.assign('/auth/login');
            resolve(response);
          }
          resolve(response);
        });
    });
  };
  post = (route, params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .post(baseUrl + route, params, {
          headers: {
            ['Authorization']: getToken(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response && response.status === 401) {
            localStorage.clear();
            window.location.assign('/auth/401');
            reject(response);
          } else {
            resolve(response);
          }
        });
    });
  };
  formDataPost = (route, params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .post(baseUrl + route, params, {
          headers: {
            ['Authorization']: getToken(),
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response && response.status === 401) {
            localStorage.clear();
            window.location.assign('/auth/401');
            reject(response);
          } else {
            resolve(response);
          }
        });
    });
  };
  resetPost = (route, params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .post(baseUrl + route, params, {
          // headers: {
          //   ['Authorization']: localStorage.getItem('resetToken'),
          // },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          
          if (response && response.status === 401) {
            localStorage.clear();
            window.location.assign('/auth/401');
            reject(response);
          }
          else {
            resolve(response);
          }
        });
    });
  };
  put = (route, params) => {
    return new Promise((resolve, reject) => {
      axios
        .put(baseUrl + route, params, {
          headers: {
            ['Authorization']: getToken(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response && response.status === 401) {
            localStorage.clear();
            window.location.assign('/auth/401');
            reject(response);
          }
          else {
            resolve(response);
          }
        });
    });
  };
  delete = (route, params) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(baseUrl + route, {
          data: params,
          headers: {
            ['Authorization']: getToken(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response && (response.status === 400 || response.status === 403)) {
            resolve(response);
          }
          if (response && response.status === 401) {
            // localStorage.clear();
            // window.location.assign('/auth/login');
            reject(response);
          }
          reject(response);
        });
    });
  };
  patch = (route, params) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(baseUrl + route, params, {
          headers: {
            ['Authorization']: getToken(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            resolve(response);
          }
          if (response.status === 401) {
            localStorage.clear();
            window.location.assign('/auth/401');
            reject(response);
          }
          resolve(response);
        });
    });
  };

  getWithToken = (route, token) => {
    return new Promise((resolve, reject) => {
      axios
        .get(baseUrl + route, {
          headers: {
            ['Authorization']: getToken(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response && response.status === 400) {
            resolve(response);
          }
          if (response && response.status === 401) {
            localStorage.clear();
            window.location.assign('/auth/401');
            reject(response);
          }
          reject(response);
        });
    });
  };
  deleteWithToken = (route, token) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(baseUrl + route, {
          headers: {
            ['Authorization']: getToken(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response && (response.status === 400 || response.status === 403)) {
            resolve(response);
          }
          if (response && response.status === 401) {
            localStorage.clear();
            window.location.assign('/auth/401');
            reject(response);
          }
          reject(response);
        });
    });
  };
}

const instance = new ApiService();
export default instance;
