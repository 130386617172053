/* eslint-disable */
import React, { useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { LoaderContext } from '../../utils/LoaderContext';
import './index.css';

const Loader = () => {
  const { isLoaderOpen } = useContext(LoaderContext);
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={isLoaderOpen}
    >
      <div className="loader-wrapper">
        <div className="loader">
          <div className="loader loader-inner"></div>
        </div>
      </div>
    </Backdrop>
  );
};
export default React.memo(Loader);
