import apiService from '../../services/ApiServices';
import { ADD_IMPLANT_TYPE, SELECTED_IMPLANT_TYPE } from '../constants';
import urlMapper from '../../services/urlMapper';

export const GetAllDataTypes = () => {
  return () => apiService.get(urlMapper.GetAllDataTypes);
};

// ///////////////////////////////////////////
// Implant Type - Axios part Action Start
// //////////////////////////////////////////
export const GetAllImplantType = () => {
  return () => apiService.get(urlMapper.GetAllImplantType);
};

export const GetImplantTypeById = () => {
  return () => apiService.get(urlMapper.GetImplantTypeById);
};

export const AddOrEditImplantType = (params) => {
  return () => apiService.post(urlMapper.AddOrEditImplantType, params);
};

export const DeleteImplantType = (params) => {
  return () => apiService.post(urlMapper.DeleteImplantType, params);
};
// ///////////////////////////////////////////
// Implant Type - Axios part Action End
// //////////////////////////////////////////

// ///////////////////////////////////////////
// Implant Type - Axios part Action Start
// //////////////////////////////////////////
export const GetAllImplantFields = () => {
  return () => apiService.get(urlMapper.GetAllImplantFields);
};

export const GetAllImplantFieldsById = (params) => {
  return () => apiService.get(urlMapper.GetAllImplantFieldsById, params);
};

export const AddOrEditImplantFields = (params) => {
  return () => apiService.post(urlMapper.AddOrEditImplantFields, params);
};

export const ChangeImplantStatus = (params) => {
  return () => apiService.post(urlMapper.ChangeImplantStatus, params);
}

export const ChangeImplantTypeStatus = (params) => {
  return () => apiService.post(urlMapper.ChangeImplantTypeStatus, params);
};

export const ChangeImplantFieldStatus = (params) => {
  return () => apiService.post(urlMapper.ChangeImplantFieldStatus, params);
};

export const GetAllImplantTypeList = () => {
  return () => apiService.get(urlMapper.GetImplantTypeForDropdown);
};

export const GetAllImplantByImplantTypeId = (params) => {
  return () => apiService.get(urlMapper.GetAllImplantByImplantTypeId, params);
};

export const GetSearchedImplantData = (params) => {
  return () => apiService.post(urlMapper.SearchImplant, params);
};

export const AddOrRemoveFavouriteImplant = (params) => {
  return () => apiService.post(urlMapper.AddOrRemoveFavorite, params);
};

export const GetAllFavoriteImplant = (params) => {
  return () => apiService.get(urlMapper.GetAllFavoriteImplant, params);
};

export const GetRelatedImplantOfShuklaParts = (params) => {
  return () => apiService.post(urlMapper.GetRelatedImplantOfShuklaParts, params);
};

export const GetAllSearchedImplantHistory = (params) => {
  return () => apiService.get(urlMapper.GetAllHistory, params);
};

export const DeleteSearchedImplantHistory = (params) => {
  return () => apiService.post(urlMapper.DeleteHistory, params);
};

export const DeleteAllSearchedImplantHistory = () => {
  return () => apiService.post(urlMapper.DeleteAllHistoryByUserId);
};

export const GetImplantDetailByImplantId = (params) => {
  return () => apiService.get(urlMapper.GetImplantById, params);
};

export const GetAllUnapprovedImplants = () => {
  return () => apiService.get(urlMapper.GetAllUnapprovedImplant);
};

export const ApproveImplant = (params) => {
  return () => apiService.post(urlMapper.ApproveImplant, params);
};

export const RejectImplant = (params) => {
  return () => apiService.post(urlMapper.RejectImplant, params);
};

export const GetAllImplants = () => {
  return () => apiService.get(urlMapper.GetAllImplant);
};

export const GetAllImplantComments = (params) => {
  return () => apiService.post(urlMapper.GetAllImplantComments, params);
};

export const AddOrRemoveLikeDislikeComments = (params) => {
  return () => apiService.post(urlMapper.LikeOrDislikeImplantComment, params);
};

export const AddOrEditImplantComment = (params) => {
  return () => apiService.post(urlMapper.AddOrEditImplantComment, params);
};

export const GetImplantNotifications = (params) => {
  return () => apiService.get(urlMapper.GetImplantNotification, params);
};

export const MarkReadImplantNotification = (params) => {
  return () => apiService.post(urlMapper.MarkReadImplantNotification, params);
};

export const DeleteImageByEntity = (params) => {
  return () => apiService.post(urlMapper.DeleteImageByEntity, params);
};

// ///////////////////////////////////////////
// Implant Type - Axios part Action End
// //////////////////////////////////////////

// Add Implant Form //
export const AddOrEditImplantForm = (params) => {
  return () => apiService.formDataPost(urlMapper.AddOrEditImplantForm, params);
};

export const demo = (data) => ({
  type: ADD_IMPLANT_TYPE,
  payload: data,
});

export const SelectedImplantType = (data) => ({
  type: SELECTED_IMPLANT_TYPE,
  payload: data,
});
