import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import chatReducer from './chats/ChatReducer';
import notesReducer from './notes/NotesReducer';
import emailReducer from './email/EmailReducer';
import AuthReducer from './auth/AuthReducer';
import CommonReducer from './common/CommonReducer';
import PickListReducer from './pickList/PickListReducer';
import ImplantReducer from './implant/ImplantReducer';
import PageViewReducer from './pageView/PageViewReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  chatReducer,
  notesReducer,
  emailReducer,
  AuthReducer,
  CommonReducer,
  PickListReducer,
  ImplantReducer,
  PageViewReducer,
});

export default RootReducers;
