import { ADD_OCCUPATION, ADD_ORGANIZATION, ADD_CANTAINER_DATA } from '../constants';

const INIT_STATE = {
  occupationList: [],
  organizationList: [],
  containerData: null,
};

const CommonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_OCCUPATION:
      return {
        ...state,
        occupationList: action.payload,
      };

    case ADD_ORGANIZATION:
      return {
        ...state,
        organizationList: action.payload,
      };

    case ADD_CANTAINER_DATA:
      return {
        ...state,
        containerData: action.payload,
      };

    default:
      return state;
  }
};

export default CommonReducer;
