/* eslint-disable */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import RootReducers from './Rootreducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['AuthReducer', 'CustomizerReducer', 'ImplantReducer'], // or blacklist to exclude specific reducers
};

const presistedReducer = persistReducer(persistConfig, RootReducers);

const store = createStore(presistedReducer, composeWithDevTools(applyMiddleware(thunk)));

const persistor = persistStore(store);

export { persistor, store };

// eslint-disable-next-line import/prefer-default-export
// export function configureStore(InitialState) {
//   const Store = createStore(
//     RootReducers,
//     InitialState,
//     composeWithDevTools(applyMiddleware(thunk)),
//   );
//   return Store;
// }
