import { FETCH_PAGEVIEW_SUCCESS, ADD_CONTAINER_LIST, ADD_PROPERTY_LIST } from '../constants';

const INIT_STATE = {
  pageViewList: [],
  containerList: [],
  propertiesList: [],
};

const PageViewReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PAGEVIEW_SUCCESS:
      return {
        ...state,
        pageViewList: action.payload,
      };

    case ADD_CONTAINER_LIST:
      return {
        ...state,
        containerList: action.payload?.container || [],
      };

    case ADD_PROPERTY_LIST:
      return {
        ...state,
        propertiesList: action.payload,
      };

    default:
      return state;
  }
};

export default PageViewReducer;
