import { AUTH_TOKEN, AUTH_TOKEN_UPDATED } from '../constants';

const INIT_STATE = {
  authUser: null,
  auth: null,
};

const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_TOKEN:
      return {
        ...state,
        auth: action.payload,
      };

    case AUTH_TOKEN_UPDATED:
      return { ...state, auth: { ...state.auth, data: action?.payload } };

    default:
      return state;
  }
};

export default AuthReducer;
