/* eslint-disable */
import {
  SELECTED_PICKLIST,
  SEARCH_PICKLIST,
  UPDATE_PICKLIST,
  DELETE_PICKLIST,
  ADD_PICKLIST,
  FETCH_PICKLIST_SUCCESS,
  FETCH_PICKLIST_VALUE_SUCCESS
} from '../constants';

const INIT_STATE = {
  pickLists: [],
  pickListsContent: null,
  pickListsSearch: '',
  pickListData : null,
  isFormEdit : false
};

const PickListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PICKLIST_SUCCESS:
      return {
        ...state,
        pickLists: action.pickLists,
      };

      case FETCH_PICKLIST_VALUE_SUCCESS:
        return {
          ...state,
          pickListData : {pickListValue: action.data},
          isFormEdit : (action?.data?.length > 0 ? true : false)
        };

    case SELECTED_PICKLIST:
      return {
        ...state,
        pickListsContent: action.id,
        pickListData : null,
        isFormEdit : false,
      };

    case SEARCH_PICKLIST:
      return {
        ...state,
        pickListsSearch: action.searchTerm,
      };

    case UPDATE_PICKLIST:
      return {
        ...state,
        pickLists: state.pickLists.map((note) =>
          note.id === action.id ? { ...note, [action.field]: action.value } : note,
        ),
      };
    case DELETE_PICKLIST:
      return {
        ...state,
        pickLists: state.pickLists.map((note) =>
          note.id === action.id ? { ...note, deleted: !note.deleted } : note,
        ),
      };
    case ADD_PICKLIST:
      return {
        ...state,
        pickLists: [
          ...state.pickLists,
          {
            // eslint-disable-next-line no-param-reassign
            id: action.id++,
            name: action.name,
            color: action.color,
            datef: new Date(),
            deleted: false
          },
        ],
      };

    default:
      return state;
  }
};

export default PickListReducer;
