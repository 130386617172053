// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';
// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// NOTES CONSTANTS
export const SELECTED_NOTES = 'SELECTED_NOTES';
export const SEARCH_NOTES = 'SEARCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';

// AUTH CONSTANTS
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_TOKEN_UPDATED = 'AUTH_TOKEN_UPDATED';

// COMMON CONSTANTS
export const ADD_OCCUPATION = 'ADD_OCCUPATION';
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';

// PICKLIST CONSTANTS
export const SELECTED_PICKLIST = 'SELECTED_PICKLIST';
export const SEARCH_PICKLIST = 'SEARCH_PICKLIST';
export const UPDATE_PICKLIST = 'UPDATE_PICKLIST';
export const DELETE_PICKLIST = 'DELETE_PICKLIST';
export const ADD_PICKLIST = 'ADD_PICKLIST';
export const FETCH_PICKLIST_SUCCESS = 'FETCH_PICKLIST_SUCCESS';
export const FETCH_PICKLIST_VALUE_SUCCESS = 'FETCH_PICKLIST_VALUE_SUCCESS';

// Impalnt Type CONSTANTS
export const ADD_IMPLANT_TYPE = 'ADD_IMPLANT_TYPE';
export const ADD_DATA_TYPE_LIST = 'ADD_DATA_TYPE_LIST';
export const IMPLANT_FILED_LIST = 'IMPLANT_FILED_LIST';
export const SELECTED_IMPLANT_TYPE = 'SELECTED_IMPLANT_TYPE';

// PAGE VIEW CONSTANTS
export const FETCH_PAGEVIEW_SUCCESS = 'FETCH_PAGEVIEW_SUCCESS';
export const ADD_CONTAINER_LIST = 'ADD_CONTAINER_LIST';
export const ADD_PROPERTY_LIST = 'ADD_PROPERTY_LIST';
export const ADD_CANTAINER_DATA = 'ADD_CANTAINER_DATA';
