import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import * as implantAction from './redux/implant/Action';
import { ADD_IMPLANT_TYPE, SELECTED_IMPLANT_TYPE } from './redux/constants/index';

const App = () => {
  const routing = useRoutes(Router);
  const dispatch = useDispatch();
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  const { auth } = useSelector((state) => state.AuthReducer);

  const getImplantType = () => {
    dispatch(implantAction.GetAllImplantTypeList())
      .then((response) => {
        if (response.status === 200) {
          const selectedImplant = JSON.parse(localStorage.getItem('selectedImplant'))
          dispatch({ type: ADD_IMPLANT_TYPE, payload: response.data });
          dispatch({ type: SELECTED_IMPLANT_TYPE, payload: selectedImplant||response.data[0]});
        }
      })
      .catch((error) => error);
  };

  React.useEffect(() => {
    if (auth?.data) {
      getImplantType();

    }
  }, [auth]);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {routing}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
