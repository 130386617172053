import {
  ADD_IMPLANT_TYPE,
  ADD_DATA_TYPE_LIST,
  IMPLANT_FILED_LIST,
  SELECTED_IMPLANT_TYPE,
} from '../constants';

const INIT_STATE = {
  implantList: [],
  dataTypeList: [],
  implantFiledList: [],
  selectedImplant: {},
};

const ImplantReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_IMPLANT_TYPE:
      return {
        ...state,
        implantList: action.payload,
      };

    case ADD_DATA_TYPE_LIST:
      return {
        ...state,
        dataTypeList: action.payload,
      };

    case IMPLANT_FILED_LIST:
      return {
        ...state,
        implantFiledList: action.payload,
      };
    case SELECTED_IMPLANT_TYPE:
      return {
        ...state,
        selectedImplant: action.payload,
      };
    default:
      return state;
  }
};

export default ImplantReducer;
