const AUTHENTICATION = 'authenticate/';
const ADMIN_COMMON = 'admin/common/';
const IMPLANT = 'implant/';
const PAGEVIEW = 'pageview/';
const DASHBOARD = 'Dashboard/';

const urlMapper = {
  login: `${AUTHENTICATION}token`,
  logOut: `${AUTHENTICATION}logout`,
  resetPassword: `${AUTHENTICATION}ForgotPassword`,

  forgetPassword: `${ADMIN_COMMON}SendForgetPasswordLink`,
  ResendActivation: `${ADMIN_COMMON}ResendActivation`,
  // signUp: `${ADMIN_COMMON}AddNewUser`,
  signUp: `${ADMIN_COMMON}AddNewUserWithImage`,
  getOrganization: `${ADMIN_COMMON}GetOrganization`,
  AddOrganization: `${ADMIN_COMMON}AddOrganization`,
  UpdateOrganization: `${ADMIN_COMMON}UpdateOrganization`,
  ChangeOrganizationStatus: `${ADMIN_COMMON}ChangeOrganizationStatus`,
  DeleteOccupations: `${ADMIN_COMMON}DeleteOccupations`,
  getOccupations: `${ADMIN_COMMON}GetOccupations`,
  AddOccupations: `${ADMIN_COMMON}AddOccupations`,
  UpdateOccupations: `${ADMIN_COMMON}UpdateOccupations`,
  ChangeOccupationStatus: `${ADMIN_COMMON}ChangeOccupationStatus`,
  DeleteOrganization: `${ADMIN_COMMON}DeleteOrganization`,
  AddOrEditPickList: `${ADMIN_COMMON}AddOrEditPickList`,
  GetAllPickList: `${ADMIN_COMMON}GetAllPickList`,
  ChangePickListStatus: `${ADMIN_COMMON}ChangePickListStatus`,
  DeletePickList: `${ADMIN_COMMON}DeletePickList`,
  AddOrEditPickListValue: `${ADMIN_COMMON}AddOrEditPickListValue`,
  GetPickListValueById: `${ADMIN_COMMON}GetPickListValueById`,
  DeletePickListValue: `${ADMIN_COMMON}DeletePickListValue`,
  AddPickListValueImage: `${ADMIN_COMMON}AddPickListValueImage`,
  ConfirmEmail: `${ADMIN_COMMON}ConfirmEmail`,
  GetAllUserDetails: `${ADMIN_COMMON}GetAllUserDetails`,
  UpdateIsAdminApproveByUserId: `${ADMIN_COMMON}UpdateIsAdminApproveByUserId`,
  UpdateIsHoldStatusByUserId: `${ADMIN_COMMON}UpdateIsHoldStatusByUserId`,
  GetModuleRightsByOccupationId: `${ADMIN_COMMON}GetModuleRightsByOccupationId`,
  GetModuleRightsByUserId: `${ADMIN_COMMON}GetModuleRightsByUserId`,
  UpdateOccupationRightsByOccupationId: `${ADMIN_COMMON}UpdateOccupationRightsByOccupationId`,
  UpdateUserRightsByUserId: `${ADMIN_COMMON}UpdateUserRightsByUserId`,
  GetUsersDetailsForDropDown: `${ADMIN_COMMON}GetUsersDetailsForDropDown`,
  DeleteUserByUserId: `${ADMIN_COMMON}DeleteUserByUserId`,
  GetUserCount: `${ADMIN_COMMON}GetUserCount`,
  // UpdateProfileById: `${DASHBOARD}UpdateProfileById`,
  UpdateProfileWithImageById: `${DASHBOARD}UpdateProfileWithImageById`,
  ChangePassword: `${DASHBOARD}ChangePassword`,

  GetAllPageView: `${PAGEVIEW}GetAllPageView`,
  AddOrEditPageView: `${PAGEVIEW}AddOrEditPageView`,
  DeletePageView: `${PAGEVIEW}DeletePageView`,

  GetAllContainerByPageViewID: `${PAGEVIEW}GetAllContainerByPageViewID`,
  getpageviewbyPageViewId: `${PAGEVIEW}getpageviewbyPageViewId`,
  AddOrEditPageViewContainer: `${PAGEVIEW}AddOrEditPageViewContainer`,
  DeletePageViewContainer: `${PAGEVIEW}DeletePageViewContainer`,

  AddOrEditPageViewProperty: `${PAGEVIEW}AddOrEditPageViewProperty`,
  GetAllPageViewProperty: `${PAGEVIEW}GetAllPageViewProperty`,
  DeletePageViewProperty: `${PAGEVIEW}DeletePageViewProperty`,
  GetpageviewByImplantType: `${PAGEVIEW}GetPageViewByImplantType`,

  GetAllRecentlyViewed: `${PAGEVIEW}GetAllRecentlyViewed`,
  DeleteRecentlyViewedById: `${PAGEVIEW}DeleteRecentlyViewedById`,

  GetAllPageViewFields: `${ADMIN_COMMON}AddOrEditPageView`,
  AddOrEditPageViewFields: `${ADMIN_COMMON}AddOrEditPageViewFields`,

  GetAllDataTypes: `${IMPLANT}GetAllDataTypes`,
  GetAllImplantType: `${IMPLANT}GetAllImplantType`,
  GetImplantTypeById: `${IMPLANT}GetImplantTypeById`,
  AddOrEditImplantType: `${IMPLANT}AddOrEditImplantType`,
  DeleteImplantType: `${IMPLANT}DeleteImplantType`,
  ChangeImplantStatus: `${IMPLANT}ChangeImplantStatus`,
  ChangeImplantTypeStatus: `${IMPLANT}ChangeImplantTypeStatus`,
  ChangeImplantFieldStatus: `${IMPLANT}ChangeImplantFieldStatus`,
  GetAllImplantFields: `${IMPLANT}GetAllImplantFields`,
  GetAllImplantFieldsById: `${IMPLANT}GetAllImplantFieldsById`,
  AddOrEditImplantFields: `${IMPLANT}AddOrEditImplantFields`,
  AddOrEditImplantForm: `${IMPLANT}AddOrEditImplantForm`,
  GetImplantTypeForDropdown: `${IMPLANT}GetImplantTypeForDropdown`,
  GetAllImplantByImplantTypeId: `${IMPLANT}GetAllImplantByImplantTypeId`,
  SearchImplant: `${IMPLANT}SearchImplant`,
  AddOrRemoveFavorite: `${IMPLANT}AddOrRemoveFavorite`,
  GetAllFavoriteImplant: `${IMPLANT}GetAllFavoriteImplant`,
  GetRelatedImplantOfShuklaParts: `${IMPLANT}GetRelatedImplantOfShuklaParts`,
  GetAllHistory: `${IMPLANT}GetAllHistory`,
  DeleteHistory: `${IMPLANT}DeleteHistory`,
  GetImplantById: `${IMPLANT}GetImplantById`,
  GetAllUnapprovedImplant: `${IMPLANT}GetAllUnapprovedImplant`,
  ApproveImplant: `${IMPLANT}ApproveImplant`,
  RejectImplant: `${IMPLANT}RejectImplant`,
  DeleteAllHistoryByUserId: `${IMPLANT}DeleteAllHistoryByUserId`,
  GetAllImplant: `${IMPLANT}GetAllImplant`,
  GetAllImplantComments: `${IMPLANT}GetAllImplantComments`,
  LikeOrDislikeImplantComment: `${IMPLANT}LikeOrDislikeImplantComment`,
  AddOrEditImplantComment: `${IMPLANT}AddOrEditImplantComment`,
  GetImplantNotification: `${IMPLANT}GetImplantNotification`,
  MarkReadImplantNotification: `${IMPLANT}MarkReadImplantNotification`,
  DeleteImageByEntity: `${IMPLANT}DeleteImageByEntity`,
};

export default urlMapper;
